import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql, PageProps } from "gatsby"
import { createImpress } from "src/utils/impress"

const Legal = ({ data, location }: PageProps<any>) => {
  const impressRef = React.useRef<HTMLDivElement>(null)
  const siteTitle = data.site.siteMetadata.title

  React.useEffect(() => {
    const impressElement = impressRef.current
    if(impressElement != null) {
      impressElement.appendChild(createImpress())
    }

  })
  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="legal" />
      <section>
        <h1>Privacy</h1>
        <p>I don't use cookies on this site.</p>
        <p>
          I use <a href="https://plausible.io/">plausible.io</a> to track website usage.
          It does not collect any personal data and uses no cookies. See their <a href="https://plausible.io/privacy-focused-web-analytics">Blog post about privacy.</a>
        </p>
      </section>
      <section>
        <h1>Impress</h1>
        <p>Person responsible for the contents of this site:</p>
        <p ref={impressRef}>
        </p>
      </section>
    </Layout>
  )
}

export default Legal

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
